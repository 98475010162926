import React, { useLayoutEffect, useState } from 'react'
import { globalHistory, HistoryUnsubscribe } from '@reach/router'
import PubSub from 'pubsub-js'
import {
  APP_PAGE_ANIMATION,
  APP_PAGE_ANIMATION_COMPLETED,
  APP_MENU_TOGGLE,
} from '@/events'
import { useSitePages } from '@/hooks/useSitePages'
import CircularWipe from '@/components/CircularWipe'
import { ContentMotionContainer } from './styled'
import debounce from 'lodash.debounce'
import { useSwipeable } from 'react-swipeable'
import { getSanitizedLocation } from '@/modules/utils/location'
import { CustomNavigate } from '@/modules/utils/CustomNavigate'

interface Settings {
  backgroundColor: string
  useCircularWipe?: boolean
  contentAnimationVariants?: Record<string, unknown>
  isSubPage?: boolean
}

interface Props {
  content: React.ReactNode
  settings: Settings
}

let isInitial = true
let navigationEnabled = true
let isShowingMenu = false

const Page: React.FC<Props> = ({ content, settings }) => {
  const {
    backgroundColor,
    contentAnimationVariants = {
      enter: {},
      exit: {},
      initial: {},
    },
    useCircularWipe,
    isSubPage = false,
  } = settings

  const [state, setState] = useState({
    circularWipeColor: '#000',
  })

  const isServer = typeof window === 'undefined'
  const sitePages = useSitePages()
  let pages = null
  const { circularWipeColor } = state

  const navigateToNextPage = (page: 'previous' | 'next') => {
    // console.log('navigateToNextPage!')
    // navigationEnabled = false
    let currentPath = getSanitizedLocation(window.location.pathname)
    if (currentPath === '/') {
      currentPath = '/home'
    }
    const urlParts = currentPath.split('/').slice(1)
    const mainSection = urlParts[0]
    pages = sitePages[mainSection]
    if (!pages) {
      return
    }
    const subSectionNumber = pages.indexOf(currentPath)
    if (subSectionNumber === -1) {
      navigationEnabled = true
      return
    }
    const nextPage =
      pages[page === 'previous' ? subSectionNumber - 1 : subSectionNumber + 1]
    if (nextPage) {
      CustomNavigate(nextPage)
    } else {
      navigationEnabled = true
    }
  }

  const onWheel = (e: MouseWheelEvent) => {
    if (!navigationEnabled || isShowingMenu) {
      return
    }
    let delta = Math.sign(e.deltaY)
    if (navigator.platform.indexOf('Win') > -1) {
      delta = -delta
    }

    if (delta === -1) {
      navigateToNextPage('next')
    } else {
      navigateToNextPage('previous')
    }
  }

  const onSwipedLeft = () => {
    navigateToNextPage('next')
  }

  const onSwipedRight = () => {
    navigateToNextPage('previous')
  }

  const handlers = useSwipeable({
    onSwipedRight: debounce(onSwipedRight, 300),
    onSwipedDown: debounce(onSwipedRight, 300),
    onSwipedLeft: debounce(onSwipedLeft, 300),
    onSwipedUp: debounce(onSwipedLeft, 300),
  })

  const addDomEvents = () => {
    document.addEventListener('wheel', onWheel)
  }

  const removeDomEvents = () => {
    document.removeEventListener('wheel', onWheel)
  }

  let removeHistoryListener: HistoryUnsubscribe = null
  const onPageAnimation = (topic) => {
    if (isInitial) {
      isInitial = false
    } else {
      navigationEnabled = topic === APP_PAGE_ANIMATION_COMPLETED
    }
  }
  const onMenuToggle = (topic: string, isOpen: boolean) => {
    isShowingMenu = isOpen
  }
  useLayoutEffect(() => {
    if (useCircularWipe) {
      document.body.style.backgroundColor = backgroundColor
    }
    const token = PubSub.subscribe(APP_PAGE_ANIMATION, onPageAnimation)
    const menuToken = PubSub.subscribe(APP_MENU_TOGGLE, onMenuToggle)
    addDomEvents()

    removeHistoryListener = globalHistory.listen(({ location }) => {
      switch (location.pathname.replace(/\/$/, '')) {
        case '':
          setState({ ...state, circularWipeColor: '#5a7d69' })
          break
        case '/our-dna':
          setState({ ...state, circularWipeColor: '#262626' })
          break
        case '/our-platform':
          setState({ ...state, circularWipeColor: '#113f4f' })
          break
        default:
          setState({ ...state, circularWipeColor: '#000' })
      }
    })

    return () => {
      removeHistoryListener()
      removeDomEvents()
      PubSub.unsubscribe(token)
      PubSub.unsubscribe(menuToken)
    }
  }, [])

  return (
    <>
      {useCircularWipe && (
        <CircularWipe
          backgroundColor={circularWipeColor}
          duration={1}
          initialSize={0}
          left={isServer ? 0 : window.innerWidth / 2}
          top={isServer ? 0 : window.innerHeight / 2}
        />
      )}
      <ContentMotionContainer
        exit="exit"
        animate="enter"
        initial="initial"
        variants={contentAnimationVariants}
        {...(isSubPage ? {} : handlers)}
      >
        {content}
      </ContentMotionContainer>
    </>
  )
}

export default Page
