import styled from '@xstyled/styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 80%;
  flex-direction: row;
  justify-content: center;
`

export const TransitionLayer = styled.div`
  background: transparent;
  height: 100vh;
  position: fixed;
  width: 100vw;
`

export const ContentMotionContainer = styled(motion.div)`
  width: 100vw;
`
