import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularWipeContainer } from './styled'

interface Props {
  initialSize: number
  backgroundColor: string
  duration: number
  left: number
  top: number
  playOn?: string
  easing?:
    | 'anticipate'
    | 'backIn'
    | 'backOut'
    | 'backInOut'
    | 'circIn'
    | 'circOut'
    | 'circInOut'
    | 'easeIn'
    | 'easeOut'
    | 'easeInOut'
    | 'linear'
  onComplete?: () => void
}
const CircularWipe: React.FC<Props> = ({
  backgroundColor,
  duration,
  initialSize,
  left,
  playOn,
  top,
  easing,
  onComplete,
}) => {
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden'
  })
  const onCircularWipeCompleted = () => {
    document.body.style.overflow = 'initial'
    if (onComplete) {
      onComplete()
    }
  }
  const isServer = typeof window === 'undefined'
  let innerWidth: number
  let innerHeight: number
  if (!isServer) {
    innerWidth = window.innerWidth
    innerHeight = window.innerHeight
  } else {
    innerWidth = 0
    innerHeight = 0
  }
  const halfWidth = innerWidth / 2
  const halfHeight = innerHeight / 2
  const circleRectangleCoverMargin = 600
  const farthestCornerDistanceLeft = left < halfWidth ? innerWidth - left : left
  const farthestCornerDistanceTop = top < halfHeight ? innerHeight - top : top
  const compensationMargin =
    farthestCornerDistanceLeft > farthestCornerDistanceTop
      ? farthestCornerDistanceLeft
      : farthestCornerDistanceTop

  let maxSize =
    innerWidth > innerHeight
      ? innerWidth + compensationMargin
      : innerHeight + compensationMargin

  maxSize += circleRectangleCoverMargin

  const centerMargin = Math.ceil(-maxSize / 2)
  const negativeCenterMargin = -Math.ceil(initialSize / 2)

  return (
    <CircularWipeContainer
      animate={playOn === 'enter' ? 'exit' : 'initial'}
      backgroundColor={backgroundColor}
      exit={playOn === 'enter' ? null : 'exit'}
      left={`${left}px`}
      top={`${top}px`}
      initial="initial"
      variants={{
        exit: {
          height: maxSize,
          marginLeft: centerMargin,
          marginTop: centerMargin,
          transition: {
            duration,
            ease: easing,
          },
          width: maxSize,
        },
        initial: {
          height: initialSize,
          marginLeft: negativeCenterMargin,
          marginTop: negativeCenterMargin,
          width: initialSize,
        },
      }}
      onAnimationComplete={onCircularWipeCompleted}
    />
  )
}

CircularWipe.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  easing: PropTypes.oneOf([
    'anticipate',
    'backIn',
    'backOut',
    'backInOut',
    'circIn',
    'circOut',
    'circInOut',
    'easeIn',
    'easeOut',
    'easeInOut',
    'linear',
  ]),
  initialSize: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
  playOn: PropTypes.string,
  top: PropTypes.number.isRequired,
}

CircularWipe.defaultProps = {
  easing: 'circIn',
  onComplete: null,
  playOn: 'exit',
}

export default CircularWipe
