export const pageVerticalFadeIn = (duration = 1): Record<string, unknown> => ({
  enter: {
    opacity: 1,
    transition: {
      duration,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 1,
    transition: {
      duration,
    },
  },
  initial: {
    opacity: 0,
  },
})

export const pageVerticalSlideAndFadeIn = (
  duration = 1,
): Record<string, unknown> => ({
  enter: {
    opacity: 1,
    transition: {
      duration,
      ease: 'easeOut',
    },
    y: 0,
  },
  exit: {
    opacity: 1,
    transition: {
      duration,
    },
  },
  initial: {
    opacity: 0,
    y: '-25vh',
  },
})

export const pageVerticalSlideAndFadeInOut = (
  fadeInDuration = 1,
  fadeOutDuration = 1,
): Record<string, unknown> => ({
  enter: {
    opacity: 1,
    transition: {
      duration: fadeInDuration,
      ease: 'easeOut',
    },
    y: 0,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: fadeOutDuration * 0.5,
      ease: 'easeIn',
    },
    y: '5vh',
  },
  initial: {
    opacity: 0,
    y: '-25vh',
  },
})

export const pageFadeOut = (fadeOutDuration = 1): Record<string, unknown> => ({
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: fadeOutDuration * 0.5,
      ease: 'easeIn',
    },
    y: '5vh',
  },
  initial: {
    opacity: 1,
    y: 0,
  },
})

export const pageFadeInOut = (
  fadeInDuration = 0.125,
  fadeOutDuration = 0.125,
): Record<string, unknown> => ({
  enter: {
    opacity: 1,
    transition: {
      duration: fadeInDuration,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: fadeOutDuration,
      ease: 'circOut',
    },
  },
  initial: {
    opacity: 0,
  },
})

export const pageIntroWait = (duration = 2): Record<string, unknown> => ({
  enter: {
    opacity: 1,
    transition: {
      duration,
      ease: 'easeOut',
    },
    y: 0,
  },
  exit: {
    opacity: 1,
    transition: {
      duration,
    },
  },
  initial: {
    opacity: 1,
    y: 0,
  },
})
