import styled from '@xstyled/styled-components'
import { motion } from 'framer-motion'

interface Props {
  readonly backgroundColor: string
  readonly left: string | number
  readonly top: string | number
}

export const CircularWipeContainer = styled(motion.div)<Props>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  left: ${(props) => props.left};
  position: absolute;
  top: ${(props) => props.top};
  z-index: 1;
`
